$(document).ready(function(){
	/* infield labels */
	$("#contactform").addClass("infield-active");
	$(".infield").inFieldLabels();

	/*** mobile nav ***/
	mobileNav();

	/** enable map scrolling- when masked **/
	$("#map-click").click(function(){
		$("#map-click").hide();
	});
});

$(window).resize(function(){
	mobileNav();
});

$(window).on("load", function(){
	postLoadFacebook();

	/*** testimonials scroller ***/
	var $container = $("#testimonial-scroller .contentwrap");
	if ($container.length == 0) return;
	if($(".testimonial").length > 1){
		$container.append("<div class=\"prev\"></div><div class=\"next\"></div>");
		var $prev = $container.find(".prev");
		var $next = $container.find(".next");

		$prev.click(function(){
			renderItem(-1);
		});
		$next.click(function(){
			renderItem(1);
		});
	}

	var renderItem = function(offset){
		var $oldItem = $container.find(".testimonial.active");
		var $newItem = "";

		if (offset == 1){
			if ( $oldItem.next(".testimonial").length ){
				$newItem = $container.find(".testimonial.active").next(".testimonial");
			} else $newItem = $("#testimonial-scroller .testimonial").first();

			$oldItem.removeClass("active");
			$newItem.addClass("active");
		} else {
			if ( $oldItem.prev(".testimonial").length ){
				$newItem = $container.find(".testimonial.active").prev(".testimonial");
			} else $newItem = $("#testimonial-scroller .testimonial").last();

			$oldItem.removeClass("active");
			$newItem.addClass("active");
		}
	};
});

export function mobileNav(){
	if (((typeof matchMedia !== "undefined" && matchMedia) && matchMedia("(max-width:600px)").matches)
        ||
        ((typeof matchMedia === "undefined") && $(window).width() <= 600)) {
		$("header nav").hide();

		$("#mobile-menu").click(function(){
			$("header nav").toggle();
		});
	} else {
		$("header nav").show();
	}
}

export function postLoadFacebook(){
	$(".fb .post a").append("<img src=\"/app/img/loader.gif\" alt=\"loader\" class=\"loader\"/>");
	$.ajax({
		url: "/FbProxy/posts?limit=2&fields=message,created_time,id,full_picture,name,from",
		success:function(data) {
			if (data.data && data.data.length) {
				var $like = $(".like-bar .contentwrap");
				$like.prepend("<div class=\"profile\"><a href=\"https://facebook.com/1726326694248098\" target=\"_blank\"><img class=\"profile-pic\" src=\"https://graph.facebook.com/1726326694248098/picture?width=80\" /><h4>"+ data.data[0].from.name + "</h4></a></div>");
				
				for (var i=0; i< data.data.length;i++){
					var item = data.data[i];
					var $el = $(".fb-post").eq(i);
					if ($el.length == 0) break;
					if (item.full_picture ) {
						$el.prepend("<img class=\"fb-img\" src=" + item.full_picture + " />");
						$el.addClass("img");
					}
					if (item.created_time.substring(item.created_time.length-3,1) != ":") {
						item.created_time=item.created_time.substring(0,item.created_time.length-2)+":"+item.created_time.substring(item.created_time.length-2,item.created_time.length);
					}
					var postdate = new Date(Date.parse(item.created_time));
					var months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
					$el.find(".text").append("<h4></h4> <h4></h4> <p></p>");
					$el.find("h4").first().html(item.from.name);
					$el.find("h4").first().next("h4").html( postdate.getDate().pad()+" "+months[postdate.getMonth()]+" "+postdate.getFullYear());
					var content = ( item.story || item.message || item.name );
					if (content.length > 200){
						content = content.substring(0,200) + " ...";
						$el.attr("title", "Click for more");
					}
					$el.find("p").html(content);
					var postid = item.id.split("_")[1];
					$el.attr("href","http://facebook.com/1726326694248098/posts/"+postid);
					$el.find(".loader").remove();
				}
			}
		},
		error: function(){
			$(".fb .contentwrap").html("<p class=\"error\">Error connecting to Facebook Posts. Please try again later</p>");
		}
	});
}


Number.prototype.pad = function(size) {
	var s = String(this);
	while (s.length < (size || 2)) {s = "0" + s;}
	return s;
};